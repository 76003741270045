import { css } from '@emotion/react';
import Color from 'color';
import { colorsScheme, primaryColors } from '../constants/colors';

export const getGlobalCss = (scheme: 'dark' | 'light' = 'light') => css`
    :root {
        --primary-color: ${Color(colorsScheme[scheme]['primary-color']).array().join(', ')};
        --secondary-color: ${Color(colorsScheme[scheme]['secondary-color']).array().join(', ')};
        --border-color: ${colorsScheme[scheme]['border-color']};
        --secondary-bg: ${colorsScheme[scheme]['secondary-bg']};
        --shadow: ${colorsScheme[scheme].shadow};
        --navy-solid-5: ${colorsScheme[scheme]['navy-solid-5']};
        --navy-solid-10: ${colorsScheme[scheme]['navy-solid-10']};
        --navy-solid-15: ${colorsScheme[scheme]['navy-solid-15']};
        --navy-solid-30: ${colorsScheme[scheme]['navy-solid-30']};
        --navy-solid-50: ${colorsScheme[scheme]['navy-solid-50']};
        --navy-solid-70: ${colorsScheme[scheme]['navy-solid-70']};
        --spinner-color: ${colorsScheme[scheme]['spinner-color']};
        --shadow-default-opacity: ${colorsScheme[scheme]['shadow-default-opacity']};
        --navy: ${colorsScheme[scheme].navy};
        --polaris-green: ${colorsScheme[scheme].polarisGreen};
        --card-gradient-hex-first-color: ${colorsScheme[scheme]['card-gradient-hex-first-color']};
        --table-cell-color: ${colorsScheme[scheme]['table-cell-color']};
        --risd-blue: ${Color(primaryColors.blue).array().join(', ')};

        --card-gradient: ${scheme === 'dark'
            ? 'linear-gradient(135deg, rgba(51, 61, 76, 0.3) 0%, rgba(31, 36, 46, 0.3) 101.75%)'
            : 'linear-gradient(113deg, rgba(249, 249, 249, 0.50) 0%, rgba(240, 234, 243, 0.50) 100%)'};
        --card-gradient-hex: ${scheme === 'dark'
            ? 'linear-gradient(135deg, #333d4c 0%, #1f242e 101.75%)'
            : 'linear-gradient(113deg, #f9f9f9 0%, #f0eaf3 100%)'};
        --body-bg: ${scheme === 'dark'
            ? 'linear-gradient(113deg, #192736 0%, #18101d 100%), #1a1f26'
            : colorsScheme.light['secondary-bg']};
        --shadow-color: ${scheme === 'dark' ? 'rgba(10, 12, 15, 0.25)' : 'rgba(10, 12, 15, 0.05)'};
    }

    html,
    body,
    #__next {
        font-family: Manrope, system-ui, sans-serif;
        background: ${colorsScheme[scheme]['secondary-color']};
        color: ${colorsScheme[scheme]['primary-color']};
        padding: 0;
        margin: 0;
        width: 100%;
        min-height: 100dvh;
    }

    /* Tailwindcss pre-flight interferes with Mantine button components visibility.
In order to fix that we need to disabling pre-flight in the config, which leads to
an additional 8px margin on the body. This is a hack to offset that margin */
    body,
    main {
        padding: 0;
        margin: 0;
    }

    *,
    ::before,
    ::after {
        border-width: 0;
        border-style: solid;
        /* border-color:rgba(255, 255, 255, 0.3); */
    }

    * {
        box-sizing: border-box;
    }

    @keyframes terminal {
        from {
            transform: translate(100%);
        }
        to {
            transform: translate(-100%);
        }
    }

    @keyframes globeDotLayer {
        from {
            width: 0px;
        }
        50% {
            width: 100px;
        }
        to {
            width: 0px;
        }
    }

    @keyframes eventCard {
        from {
            transform: translate(500px);
        }
        33% {
            transform: translate(0px);
        }
        85% {
            transform: translate(0px);
        }
        to {
            transform: translate(0px, 200px);
        }
    }

    .hideScroll::-webkit-scrollbar {
        display: none;
    }
    .hideScroll {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .glass-effect {
            backdrop-filter: blur(17px);
            -webkit-backdrop-filter: blur(17px);
            background: rgba(255, 255, 255, 0.08);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        }
    }

    /* fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .glass-effect {
            background-color: rgba(var(--shadow), 0.6) !important;
        }
    }

    .mapbox-logo {
        display: none;
    }
    .mapboxgl-ctrl-logo {
        display: none !important;
    }
    .mapbox-improve-map {
        display: none;
    }
    .mapboxgl-ctrl-compass {
        display: none;
    }

    /* fix mantine slider track color issue. Source code has hard coded BG color... */
    .mantine-Slider-root .mantine-Slider-track::before {
        background-color: inherit;
    }

    .mantine-Skeleton-visible {
        ::before {
            background: ${scheme === 'dark' ? '#333d4c' : 'gray'};
        }
        ::after {
            background: ${scheme === 'dark' ? '#1f242e' : '#f0eaf3'};
        }
    }

    .mantine-Drawer-body {
        background: var(--card-gradient);
    }

    .body-bg {
        background: var(--body-bg);
    }
    .gradient-bg {
        background: var(--card-gradient);
    }

    .gradient-bg-hex {
        background: var(--card-gradient-hex);
    }
`;
