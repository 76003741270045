/* eslint-disable @next/next/no-img-element */
import { Button, Input, PasswordInput } from '@mantine/core';
import Head from 'next/head';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useAppContext } from '../contexts/appContext';
import Text from '../andromeda/text';

const commonStyling: React.CSSProperties = {
    textAlign: 'center',
    background: '#22262d',
    color: '#B1B5C1',
};

const Login = () => {
    const {
        googleLogin,
        microsoftLogin,
        microsoftSiberiaLogin,
        credentialLogin,
        googleSiberiaLogin,
    } = useAppContext();
    const [formError, setFormError] = useState<string>();
    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const { username, password } = e.currentTarget as typeof e.currentTarget & {
                username: { value: string };
                password: { value: string };
            };
            const res = await credentialLogin({
                username: username.value,
                password: password.value,
            });
            if (res === 'You do not have permission to perform this action.') {
                setFormError('Invalid Credentials');
                username.value = '';
                password.value = '';
            }
        },
        [credentialLogin],
    );

    return (
        <div className="flex h-screen flex-col items-center justify-center">
            <Head>
                <title>Arena</title>
                <link rel="shortcut icon" href="images/favicon.png" />
            </Head>
            <div className="flex flex-row items-center justify-center">
                <img
                    alt="arena"
                    src="images/arenaLogoFull.svg"
                    style={{
                        height: '42px',
                        marginBottom: '10px',
                        width: 'auto',
                        color: '#fff',
                        fill: '#fff',
                    }}
                />
            </div>
            <div className="mb-3 flex gap-2 border-b-1 border-border-color p-3">
                {/* <Button
                    type="button"
                    variant="default"
                    data-testid="login-google"
                    onClick={() => {
                        googleLogin();
                    }}
                >
                    Fusion Google Login
                </Button> */}
                <Button
                    type="button"
                    variant="default"
                    data-testid="login-google"
                    onClick={() => {
                        googleSiberiaLogin();
                    }}
                >
                    Spaceport Google Login
                </Button>
                {/* <Button
                    type="button"
                    variant="default"
                    data-testid="login-microsoft"
                    onClick={() => {
                        microsoftLogin();
                    }}
                >
                    Microsoft Login
                </Button>
                <Button
                    type="button"
                    variant="default"
                    data-testid="login-microsoft"
                    onClick={() => {
                        microsoftSiberiaLogin();
                    }}
                >
                    Microsoft Siberia Login
                </Button> */}
            </div>
            <div>
                <form onSubmit={handleSubmit} className="flex w-full min-w-[275px] flex-col gap-2">
                    <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                        className="focus-within:!border-secondary"
                        styles={{
                            input: {
                                ...commonStyling,
                                paddingRight: '26px',
                            },
                        }}
                    />
                    <PasswordInput
                        name="password"
                        placeholder="Password"
                        id="password"
                        className="focus-within:!border-secondary"
                        styles={{
                            innerInput: { ...commonStyling },
                        }}
                    />
                    <Button type="submit" variant="default">
                        Login
                    </Button>
                    <Text
                        type="Button/regular"
                        className={classNames('text-red-600 text-center', {
                            hidden: !formError,
                        })}
                    >
                        {formError}
                    </Text>
                </form>
            </div>
        </div>
    );
};

export default Login;
