import { useRouter } from 'next/router';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import isMobile from 'ismobilejs';
import classNames from 'classnames';
import NavBar from './navBar/navBar';
import ReactHeap from '../utils/heap';
import { HEAP_ID } from '../buildConfig/processEnv';
import { useAppContext } from '../contexts/appContext';
import Login from './login';
import AppLoading from './appLoading';
import RegisterForm from './userDetails';
import MobileNavBar from './MobileNavBar';

const DETAIL_OPTIONS = ['true', 'skip'];

function Layout({
    children,
    title,
    className,
    style = {},
}: {
    children: React.ReactNode;
    title: string;
    className?: string;
    style?: React.CSSProperties;
}) {
    const { user, fetchingToken, handleUser, verifyToken } = useAppContext();
    const router = useRouter();
    const [isDetails, setIsDetails] = useState<boolean>(false);

    useEffect(() => {
        if (HEAP_ID) ReactHeap.initialize(HEAP_ID);
    }, []);

    const mobile = isMobile().phone;

    if (fetchingToken) return <AppLoading>Authenticating user</AppLoading>;
    if (!user || (!user.token && !user.siberiaToken) || !user.isLoggedIn) return <Login />;
    if (!user?.groups && !!user?.token) return <AppLoading>Loading user data</AppLoading>;

    return (
        <div className="dark">
            <Head>
                <title>{title}</title>
                <link rel="shortcut icon" href="images/favicon.png" />
            </Head>
            {/* <RegisterForm setIsDetails={setIsDetails} isDetails={isDetails} /> */}
            {!isDetails && (
                <div className="flex">
                    {/* // ? Product Intelligence Filter's zIndex is 301 */}
                    <div
                        className={classNames('fixed z-[400]', {
                            hidden: mobile,
                        })}
                    >
                        <NavBar />
                    </div>
                    {mobile && <MobileNavBar />}
                    <div
                        style={style}
                        className={classNames(`p-4 ml-20 grow`, className, {
                            '!ml-0 max-w-[100dvw] overflow-auto': mobile,
                        })}
                    >
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Layout;
