import React, { useState } from 'react';
import { AppShell, AppShellNavbar, Burger, Image, Menu } from '@mantine/core';
import { useRouter } from 'next/router';
import Link from 'next/link';

function NavBar() {
    const router = useRouter();

    const [opened, setOpened] = useState(false);

    const depletionPatch = ['/depletion'];

    const text = depletionPatch.includes(router.pathname)
        ? 'Quarterly Depletion Recap'
        : 'Nielsen Market Recap';
    const subtext = depletionPatch.includes(router.pathname) ? '2023 Q1 / Key Premium Markets' : '';
    return (
        <AppShell>
            <AppShell.Navbar
                className="fixed top-0 flex h-[80px] w-screen flex-row justify-between gap-3 border-b-2 p-4 "
                style={{
                    backgroundImage: '/images/mobile-home/mobile-home.png',
                    backgroundSize: 'cover',
                }}
            >
                <div className="w-10">
                    <Link id="navbar-home" href="/">
                        <Image
                            src="images/favicon.png"
                            alt="logo"
                            onClick={() => router.push('/')}
                            className="cursor-pointer hover:bg-shadow-300"
                        />
                    </Link>
                </div>
                <div className=" h-8">
                    <div className="text-2xl font-bold">{text}</div>
                    <div className="text-xs font-semibold">{subtext}</div>
                </div>

                <Menu opened={opened} onChange={setOpened}>
                    <Menu.Target>
                        <Burger opened={opened} />
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item onClick={() => router.push('/depletion')}>
                            Depletion Recap
                        </Menu.Item>
                        <Menu.Item onClick={() => router.push('/market-recap')}>
                            Nielsen Market Recap
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </AppShell.Navbar>
        </AppShell>
    );
}

export default NavBar;
