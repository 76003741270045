import classNames from 'classnames';
import React from 'react';

type CustomTextType =
    | 'body/regular'
    | 'body/small'
    | 'allCaps/regular'
    | 'allCaps/small'
    | 'Button/regular'
    | 'Button/Small'
    | '10Reg'
    | '10Mid'
    | '12Reg'
    | '12Mid'
    | '12Semi'
    | '13Mid'
    | '14Light'
    | '14Reg'
    | '14Mid'
    | '14Semi'
    | '16Reg'
    | '18Reg'
    | '20Reg'
    | '22Reg';

const Text = React.forwardRef(
    (
        {
            type,
            children,
            className,
            ...rest
        }: { type: CustomTextType } & JSX.IntrinsicElements['span'],
        ref?: React.LegacyRef<HTMLSpanElement>,
    ) => (
        <span
            ref={ref}
            className={classNames('text-navy-solid-50', className, {
                'font-semibold text-xs leading-[16.39px]': type === 'body/regular',
                'font-light text-xs': type === 'body/small',
                'uppercase font-semibold text-xs': type === 'allCaps/regular',
                'uppercase font-semibold text-xxs': type === 'allCaps/small',
                'font-semibold text-[15px]': type === 'Button/regular',
                'font-semibold text-[11px]': type === 'Button/Small',
                'text-sm font-semibold leading-4.5': type === '14Semi',
                'text-sm leading-4.5 font-medium': type === '14Mid',
                'text-xs leading-3.5 font-medium': type === '12Mid',
                'text-xs leading-3.5 font-semibold': type === '12Semi',
                'text-xxs leading-3 font-medium': type === '10Mid',
                'text-[13px] leading-[17px] font-medium': type === '13Mid',
                'text-base font-normal leading-5': type === '16Reg',
                'text-xs font-normal leading-3.5': type === '12Reg',
                'text-sm font-normal leading-5': type === '14Reg',
                'text-[22px] font-normal leading-[30px]': type === '22Reg',
                'text-lg font-normal leading-[25px]': type === '18Reg',
                'text-xl font-normal leading-[25px]': type === '20Reg',
                'text-xxs font-normal leading-3.5': type === '10Reg',
                'text-sm font-light leading-4.5': type === '14Light',
            })}
            {...rest}
        >
            {children}
        </span>
    ),
);

export default Text;
export type { CustomTextType };
