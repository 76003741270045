import { useAppContext } from '../contexts/appContext';
import { UserGroup } from '../types/user';

interface UseUserAccessParams {
    userGroupWhitelist?: UserGroup[] | UserGroup;
    userGroupBlacklist?: UserGroup[] | UserGroup;
}

export const useUserAccess = ({
    userGroupWhitelist = [],
    userGroupBlacklist = [],
}: UseUserAccessParams) => {
    const {
        user: { groups },
    } = useAppContext();

    if (!groups) return false;

    const inneruserGroupWhitelist = Array.isArray(userGroupWhitelist)
        ? userGroupWhitelist
        : [userGroupWhitelist];

    const innerUserGroupBlacklist = Array.isArray(userGroupBlacklist)
        ? userGroupBlacklist
        : [userGroupBlacklist];

    return (
        (groups.some(group => inneruserGroupWhitelist.includes(group)) ||
            userGroupWhitelist.length === 0) &&
        !groups.some(group => innerUserGroupBlacklist.includes(group))
    );
};
