import type { AppProps } from 'next/app';
import { QueryClientProvider } from '@tanstack/react-query';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { Global } from '@emotion/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Notifications } from '@mantine/notifications';
import isMobile from 'ismobilejs';
import Head from 'next/head';
import { useEffect, useRef } from 'react';
import { hotjar } from 'react-hotjar';
import '../styles/global.css';

import { I18nextProvider } from 'react-i18next';
import { Analytics } from '@vercel/analytics/react';
import AppContextProvider, { useAppContext } from '../contexts/appContext';
import i18n from '../i18n';
import { queryClient } from '../utils/reactQueryConfig';
import MobileHomePage from './mobile-home';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.layer.css';
import '@mantine/code-highlight/styles.css';
import 'regenerator-runtime/runtime';
import { getGlobalCss } from '../styles/getGlobalCss';

export default function App({ Component, pageProps }: AppProps) {
    useEffect(() => {
        const id = Number(process.env.NEXT_PUBLIC_HOTJAR_ID) || 0;
        hotjar.initialize({ id, sv: 6 });
    }, []);
    const mobile = useRef(isMobile().phone);
    const { user } = useAppContext();

    return (
        <I18nextProvider i18n={i18n}>
            <MantineProvider
                // ? This is because the highest z-index is 400 (NavBar)
                defaultColorScheme="dark"
                theme={{
                    fontFamily: 'inherit',
                    headings: {
                        fontFamily: 'inherit',
                        fontWeight: '500',
                        sizes: {
                            h1: { fontSize: '28px' },
                            h2: { fontSize: '22px' },
                            h3: { fontSize: '18px' },
                            h4: { fontSize: '16px' },
                            h5: { fontSize: '14px' },
                            h6: { fontSize: '12px', lineHeight: '1rem' },
                        },
                    },
                    components: {
                        Modal: {
                            classNames: {
                                overlay: 'z-[500]',
                                inner: '!z-[501]',
                                content: 'bg-primary',
                                header: 'bg-primary',
                            },
                        },
                        Button: {
                            classNames: { loader: 'hidden' },
                        },
                    },
                }}
                withCssVariables
            >
                <Global styles={getGlobalCss('dark')} />
                <ColorSchemeScript defaultColorScheme="auto" />
                <Notifications />
                <Head>
                    {/* Disabling auto zoom in input for Safari on iPhone and setting a smaller initial scale */}
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=0.85, maximum-scale=0.85"
                    />
                </Head>
                <QueryClientProvider client={queryClient}>
                    <AppContextProvider>
                        <Analytics />
                        {mobile.current ? <MobileHomePage /> : <Component {...pageProps} />}
                    </AppContextProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </MantineProvider>
        </I18nextProvider>
    );
}
