import { useRouter } from 'next/router';
import { useState } from 'react';
import Link from 'next/link';
import _ from 'lodash';
import { NavItem, PAGE_NAMES, PagesType } from './constants';
import StyledTooltip from '../utils/styledTooltip';
import { useUserAccess } from '../../hooks/useUserAccess';
import { UserGroup } from '../../types/user';

const covertHrefToRoute = (href: string) => {
    const index = href.indexOf('?');
    if (index > -1) {
        return href.substring(0, index);
    }
    if (href.includes('/')) return href;
    return `/${href}`;
};

export const ActiveLink = ({
    href,
    children,
    className = '',
    routerQueryKeys = [],
    shareRoutesQueryKeysWith = [],
    sharableKeys = [],
    userGroupWhitelist = [],
    userGroupBlacklist = [],
}: NavItem) => {
    const { pathname, ...router } = useRouter();
    const [hrefWithCommonQueries, setHrefWithCommonQueries] = useState<string>(href);

    const hasAccess = useUserAccess({
        userGroupWhitelist,
        userGroupBlacklist: userGroupWhitelist.includes('arena-staff')
            ? userGroupBlacklist
            : (['force-remove-groups', userGroupBlacklist].flat() as UserGroup[]),
    });

    if (!hasAccess) return null;

    return (
        <StyledTooltip
            position="right"
            withArrow
            classNames={{
                tooltip: '!whitespace-nowrap z-[9999]',
            }}
            w="auto"
            withinPortal
            arrowSize={12}
            label={PAGE_NAMES[href]}
        >
            <div
                id={href}
                className="flex items-center justify-center rounded-lg opacity-50 hover:cursor-pointer hover:opacity-100"
                style={{
                    backgroundColor:
                        pathname === covertHrefToRoute(href)
                            ? 'rgba(var(--shadow),0.9)'
                            : undefined,
                    opacity: pathname === covertHrefToRoute(href) ? 1 : undefined,
                }}
                onFocus={() => {
                    if (pathname === covertHrefToRoute(href)) return;
                    if (!shareRoutesQueryKeysWith.includes(pathname.replace('/', '') as PagesType))
                        return;
                    const queriesArr = Object.entries(router.query);
                    if (queriesArr.length === 0) return;

                    const commonQueries = queriesArr.reduce(
                        (acc, [key, val]) =>
                            routerQueryKeys.includes(key) && sharableKeys.includes(key)
                                ? `${acc}${
                                      Array.isArray(val) ? val.join(`&${key}=`) : `&${key}=${val}`
                                  }`
                                : acc,
                        '?',
                    );
                    const newHref = commonQueries.replace('?&', '?');
                    if (newHref[newHref.length - 1] !== '?') {
                        // ? [hack] fix when we have a param in the ref.
                        setHrefWithCommonQueries(`/${href}&${newHref}`.replace('&?', '?'));
                    }
                }}
            >
                <Link href={hrefWithCommonQueries} className={`px-3.5 py-2.5 ${className}`}>
                    {children}
                </Link>
            </div>
        </StyledTooltip>
    );
};
