import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './translations/en.json'; // Import your translation files for different languages

// Initialize i18next
i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslations, // Set the translation object for English
        },
        // Add more languages and their translation objects here
    },
    lng: 'en', // Set the default language
    fallbackLng: 'en', // Set the fallback language
    interpolation: {
        escapeValue: false, // React already escapes the output by default, so no need for i18n to do it
    },
});

export default i18n;
